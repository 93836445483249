module.exports = {
  ENV: 'prod',
  APP_NAME: 'nantes',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'yUFHDl0nJP3FPeJai2Pbe7Be5WZ4MIob9f7j3EEc',
  BRAND_KEY: '999b7deb-8df5-4b07-9550-5d56f7d053a6',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'NANTES_AGENTS',
}